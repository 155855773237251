<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { BrowserNotification, useNotifications } from '~/utilities/data/notifications'

const { t, d } = useI18n();

const notificationStore = useNotifications();

const notifications = storeToRefs(notificationStore).notifications;

const ttl = (notification: BrowserNotification) => {
    if (!notification.time2live || notification.ttlOpen === undefined) return 100;
    let r = ((notification.ttlOpen) / notification.time2live) * 100;
    return r;
}


</script>

<template>
    <div style="z-index: 100000; position: fixed; right: 0; top: 0">
        <VAlert v-for="notification of notifications" class="ma-2" :border="'start'" :type="notification.type"
            :title="notification.title" :closable="notification?.closable || false" max-width="700"
            @click:close="notificationStore.removeNotification(notification)" :text="notification.message"
            density="compact">
            <template #default>
                <VProgressLinear v-if="notification.progress" height="25" class="mt-2" color="primary"
                    :model-value="notification.progress" striped>
                    <template v-slot:default="{ value }">
                        <strong>{{ Math.ceil(value) }}%</strong>
                    </template>
                </VProgressLinear>
                <VProgressLinear v-else-if="notification.time2live" class="mt-2" color="primary"
                    :model-value="ttl(notification)" striped>
                </VProgressLinear>
            </template>
        </VAlert>
    </div>
</template>